import classNames from 'classnames'
import { Children, isValidElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBinoculars, FaClipboardList, FaSuitcase, FaUsers } from 'react-icons/fa'
import { IoMdCart } from 'react-icons/io'
import { IconType } from 'react-icons/lib'
import { MdLocalOffer } from 'react-icons/md'
import { Link, matchPath, useLocation } from 'react-router-dom'

import { RoleId } from 'src/api'
import CenterSpinner from 'src/components/CenterSpinner'
import { AltLogoIcon, FacilityIcon, InventoryIcon, ProductionIcon, ShipmentIcon, StatisticsIcon } from 'src/icons'
import { useMenuRoutes } from 'src/route-config'
import useActiveRole from 'src/state/role'

import { IconButton, LabeledIconButton } from './Buttons'
import LoaderWrapper from './LoaderWrapper'

export default function MainMenu({ className }) {
  const [roleQuery] = useActiveRole()
  const { t } = useTranslation()

  return (
    <LoaderWrapper query={roleQuery}>
      <nav className={classNames('h-full bg-teal-500', className)}>
        <Logo className="fixed top-0 z-50 bg-white flex-grow-0" />
        <MenuWrapper>
          <MenuItem label={t('MAIN_MENU`Users')} icon={FaUsers} link="/admin/users" />
          <MenuItem label={t('MAIN_MENU`Users')} icon={FaUsers} link="/users" />
          <MenuItem label={t('MAIN_MENU`Users')} icon={FaUsers} link="/brand/users" />
          <MenuItem label={t('MAIN_MENU`Users')} icon={FaUsers} link="/project/users" />
          <MenuItem label={t('MAIN_MENU`Profile')} icon={FaClipboardList} link="/profile" />
          <MenuItem label={t('MAIN_MENU`Profile')} icon={FaClipboardList} link="/handler-profile" />
          <MenuItem label={t('MAIN_MENU`Profile')} icon={FaClipboardList} link="/manufacturer-profile" />
          <MenuItem label={t('MAIN_MENU`Manufacturers')} icon={InventoryIcon} link="/suppliers-overview" />
          <MenuItem label={t('MAIN_MENU`Inventory')} icon={InventoryIcon} link="/inventory" />
          <MenuItem label={t('MAIN_MENU`Inventory')} icon={InventoryIcon} link="/inventory/facility/:id" />
          <MenuItem label={t('MAIN_MENU`Inventory')} icon={InventoryIcon} link="/admin/inventory" />
          <MenuItem label={t('MAIN_MENU`Facilities')} icon={FacilityIcon} link="/facilities" />
          <MenuItem
            label={
              roleQuery.data?.roleId === RoleId.BrandManager
                ? t('MAIN_MENU`My Manufacturers')
                : t('MAIN_MENU`Manufacturers')
            }
            icon={FacilityIcon}
            link="/manufacturers"
          />
          <MenuItem label={t('MAIN_MENU`Brands')} icon={MdLocalOffer} link="/admin/brands" />
          <MenuItem label={t('MAIN_MENU`Manufacturers')} icon={FacilityIcon} link="/admin/manufacturers" />
          <MenuItem label={t('MAIN_MENU`Handlers')} icon={FacilityIcon} link="/admin/handlers" />
          <MenuItem label={t('MAIN_MENU`Handlers')} icon={FacilityIcon} link="/handlers" />
          <MenuItem label={t('MAIN_MENU`Recyclers')} icon={FacilityIcon} link="/admin/recyclers" />
          <MenuItem label={t('MAIN_MENU`Organisation')} icon={FaSuitcase} link="/organization" />
          <MenuItem label={t('MAIN_MENU`Recyclers')} icon={FacilityIcon} link="/recyclers" />
          <MenuItem label={t('MAIN_MENU`Shipments')} icon={ShipmentIcon} link="/shipments" />
          <MenuItem label={t('MAIN_MENU`Shipments')} icon={ShipmentIcon} link="/admin/shipments" />
          <MenuItem label={t('MAIN_MENU`Statistics')} icon={StatisticsIcon} link="/statistics" />
          <MenuItem label={t('MAIN_MENU`Purchase orders')} icon={IoMdCart} link="/admin/purchase-orders" />
          <MenuItem label={t('MAIN_MENU`Purchase orders')} icon={IoMdCart} link="/purchase-orders" />
          <MenuItem label={t('MAIN_MENU`Production')} icon={ProductionIcon} link="/production" />
          <MenuItem label={t('MAIN_MENU`Production')} icon={ProductionIcon} link="/admin/production" />
          <MenuItem
            label={t('MAIN_MENU`Transaction overview')}
            icon={FaBinoculars}
            link="/admin/transaction-overview"
          />
          <MenuItem label={t('MAIN_MENU`Transaction overview')} icon={FaBinoculars} link="/transaction-overview" />
        </MenuWrapper>
      </nav>
    </LoaderWrapper>
  )
}

const Logo = ({ className }) => (
  <IconButton
    as={Link}
    to="/"
    className={classNames('p-3 w-20', className)}
    variantColor="darkYellow"
    icon={AltLogoIcon}
  />
)

const MenuWrapper = ({ children }) => {
  const { menuRoutes, isLoading } = useMenuRoutes()
  return isLoading ? (
    <CenterSpinner />
  ) : (
    <ul className="sticky top-10 bottom-10 mt-10 pb-5 mb-20">
      {Children.toArray(children).filter((c) => isValidElement(c) && menuRoutes.includes(c.props.link))}
    </ul>
  )
}

const MenuItem = ({ icon: Icon, link, label }: { icon: IconType; link: string; label: string }) => {
  const location = useLocation()
  const match = matchPath({ path: link, end: false }, location.pathname)

  return (
    <li>
      <LabeledIconButton
        as={Link}
        variantColor="white"
        to={link}
        icon={Icon}
        className={classNames('p-2 block', { 'bg-teal-600': match })}
        title={label}
      >
        {label}
      </LabeledIconButton>
    </li>
  )
}
