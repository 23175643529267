import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { AuthenticationErrorBoundary } from './errors'
import './i18n'
import AppRoutes from './routes'

const router = createBrowserRouter([
  {
    path: '*',
    element: <AppRoutes />,
    errorElement: (
      <AuthenticationErrorBoundary>
        <AppRoutes />
      </AuthenticationErrorBoundary>
    ),
  },
])

const App = () => <RouterProvider router={router} />

export default App
