import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export function useClassifiersTranslation(keyPrefix: string) {
  const { t } = useTranslation('classifiers', { keyPrefix })
  return t
}

export function useCategoryTransactionStatusesTranslation(isIncoming?: boolean) {
  const tx = useClassifiersTranslation('categoryTransactionStatuses')
  return useCallback((value: string) => tx(value, isIncoming ? { context: 'INCOMING' } : undefined), [isIncoming, tx])
}
